import { AppBar, Box, Toolbar, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';


export default function Layout({ children, title }) {

    const navigate = useNavigate();
    const location = useLocation();
    const isFarmerPage = location.pathname === '/orders/farmer';
    const handleLogout = (event) => {
        localStorage.clear()
        window.location.href = '/login_farmer'
    };
    
    return (
        <div>
            <AppBar position="static" sx={{ backgroundColor: '#7da66a' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
                    {!isFarmerPage && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => navigate(-1)}
                            sx={{ position: 'absolute', left: 8 }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    )}

                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}
                    >
                        {title}
                    </Typography>

                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleLogout} // แทนที่ด้วยฟังก์ชัน handleLogout ของคุณ
                        sx={{ position: 'absolute', right: 8 }}
                    >
                        <LogoutIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>


            <Box>
                {children}
            </Box>
        </div>
    );
}