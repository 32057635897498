import { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { geySalesOrdersFarmer, getBatchSalesOrders, orderUpdateSlip } from "../../../../services/orders"
import { IconButton, Tooltip, Typography } from "@mui/material"
import Table from "../../../../components/table"
import Layout from "../../../../components/layout"
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button } from "@mui/material"
import FileUploadIcon from '@mui/icons-material/FileUpload';
import moment from "moment"
import ReceiptIcon from '@mui/icons-material/Receipt';
const config = require('../../../../config')

const API_URL = config.API_URL
const HistoryDetail = () => {

    const { order_id } = useParams()
    const [order_list, setOrderList] = useState(true)
    const [open_slip, setOpenslip] = useState(false)
    const [open_slip_role_fac, setOpenslipRoleFac] = useState(false)

    const [img, setIMG] = useState(null)
    let token = localStorage.getItem('accessToken')
    let role_id = localStorage.getItem('role_id')
    let user_id = localStorage.getItem('user_id')
    const [selectedFile, setSelectedFile] = useState(null)
    const [img_upload, setIMGUpload] = useState('')
    const [confirm_payment, setConfirmPayment] = useState(null)

    const [farmer_order_id, setFarmerOrderId] = useState('')


    let navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getBatchSalesOrders({ order_id, role_id }, token);
                const data = res.data.data;
                   console.log("data",data)
                const promises = data.map(item =>
                    geySalesOrdersFarmer({ user_id, order_id: item.from_order_id }, token)
                        .then(res => {
                            if (res.status === 200) {
                                return res.data.data[0];
                            }
                            return null; // ถ้า status ไม่ใช่ 200 จะ return null
                        })
                        .catch(error => {
                            console.error(error);
                            if (error.response?.status === 403) {
                                navigate('/login');
                            }
                            return null; // กรณี error ให้ return null
                        })
                );

                const results = await Promise.all(promises);

                const list = results
                    .filter(item => item !== null) // กรอง item ที่เป็น null
                    .map(item => ({
                        id: item.id,
                        orders_no: item.orders_no,
                        orderer: item.user.firstname + ' ' + item.user.lastname,
                        order_date: moment(item.create_date).format('DD MMM YYYY'),
                        delivery_date: item.delivery_date
                            ? moment(item.delivery_date).format('DD MMM YYYY')
                            : '',
                        product: item.product.name,
                        status: item.status_desc,
                        quantity: item.total_weight + ' ' + item.product.unit,
                        img_slip: item.img_slip,
                        confirm_payment:item.confirm_payment
                    }));

                console.log("list", list);
                setOrderList(list);
            } catch (error) {
                console.error("Error fetching batch sales orders", error);
            }
        };

        fetchData();
    }, [order_id, role_id, token, user_id, navigate]);




    const columns = [
        { field: 'orders_no', headerName: 'เลขคำสั่งซื้อ', flex: 0.2, align: 'center' },
        { field: 'orderer', headerName: 'เกษตรกร', flex: 0.3, align: 'center' },
        { field: 'order_date', headerName: 'วันที่สั่ง', flex: 0.2, align: 'center' },
        { field: 'status', headerName: 'สถานะ', flex: 0.2, align: 'center' },
        { field: 'product', headerName: 'สินค้า', flex: 0.2, align: 'center' },
        { field: 'quantity', headerName: 'จำนวน', flex: 0.2, align: 'center' },
        { field: 'delivery_date', headerName: 'วันที่ส่งมอบ', flex: 0.2, align: 'center' },
        // {
        //     field: "slip",
        //     headerName: "อัพโหลดslip",
        //     flex: 0.2,
        //     align: 'center',
        //     renderCell: (params) => (
        //         <Tooltip title={<Typography>อัพโหลดslip</Typography>} >
        //             <IconButton color="primary" onClick={() => showSlip(params.row)}>
        //                 <FileUploadIcon sx={{ color: '#15322D', fontSize: 40 }} />
        //             </IconButton>
        //         </Tooltip>
        //     ),
        // }

    ]
    if (role_id === '3') {
        columns.push(
            {
                field: "slip",
                headerName: "อัพโหลดslip",
                flex: 0.2,
                align: 'center',
                renderCell: (params) => (
                    <Tooltip title={<Typography>อัพโหลดslip</Typography>} >
                        <IconButton color="primary" onClick={() => showSlip(params.row)}>
                            <FileUploadIcon sx={{ color: '#15322D', fontSize: 40 }} />
                        </IconButton>
                    </Tooltip>
                ),
            });
        }
        if (role_id === '2') {
            columns.push(
                {
                    field: "check",
                    headerName: "ตรวจสอบการโอนเงิน",
                    flex: 0.2,
                    align: 'center',
                    renderCell: (params) => (
                        <Tooltip title={<Typography>ตรวจสอบการโอนเงิน</Typography>} >
                            <IconButton color="primary" onClick={() => showSlip(params.row)}>
                                <ReceiptIcon sx={{ color: '#15322D', fontSize: 40 }} />
                            </IconButton>
                        </Tooltip>
                    ),
                });
        }
        const style_assign = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            border: '1px solid #000',
            boxShadow: 24,
            p: 4,
        };

        // const showSlip = (row) => {
        //     console.log(row)
        //     setIMG(row.img_slip)
        //     setOpenslip(true)
        // }
        const showSlip = (row) => {
            setConfirmPayment(row.confirm_payment)
            setIMG(row.img_slip)
            setFarmerOrderId(row.id)
            if(role_id != 2){
                setOpenslip(true)

            }else{
                setOpenslipRoleFac(true)

            }
        }
        const update = (url) => {

            orderUpdateSlip({
                user_id: user_id,
                img_slip: url,
                order_id: farmer_order_id
            }, token)
                .then((res) => {
                    window.location.reload();
                    setOpenslip(true)
                }).catch(error => {
                    console.log(error)
                })
        }

        return (
            <Layout title='การจ่ายเงินให้เกษตรกร'>
                <Modal
                    open={open_slip}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{ zIndex: 2 }}
                >
                    <Box sx={style_assign}>
                        <IconButton
                            style={{ position: "absolute", top: "0", right: "0" }}
                            onClick={() => setOpenslip(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                        <h3>อัพโหลดหลักฐานการโอนเงิน</h3>
                        {img ? (
                            <img
                                src={img}
                                alt="หลักฐานการโอนเงิน"
                                style={{ width: "100%", height: "auto", marginTop: "20px" }}
                            />

                        ) : (
                            
                            <> <input
                                type="file"
                                accept="image/*"
                                onChange={(event) => {
                                    const file = event.target.files?.[0];
                                    if (file) {
                                        setSelectedFile(file); // เก็บไฟล์ใน state
                                    }
                                }}
                                style={{ marginTop: "20px" }} /><Button
                                    variant="contained"
                                    style={{
                                        marginTop: "20px",
                                        float: "right", // ปุ่มชิดขวา
                                    }}
                                    onClick={async () => {
                                        if (!selectedFile) {
                                            alert("กรุณาเลือกไฟล์ก่อน");
                                            return;
                                        }

                                        const formData = new FormData();
                                        formData.append("files", selectedFile);
                                        formData.append("type", 'slip');
                                        formData.append("order_id", order_id);
                                        
                                        try {
                                            const response = await fetch(API_URL+"/upload", {
                                                method: "POST",
                                                headers: {'Authorization': `Bearer ${token}`},
                                                body: formData,
                                            });
                                            

                                            if (response.status) {
                                                console.log(response)
                                                alert("อัพโหลดไฟล์สำเร็จ");
                                                const result = await response.json();
                                                setIMGUpload(result.url);
                                                update(result.url)
                                            } else {
                                                alert("การอัพโหลดล้มเหลว");
                                            }
                                        } catch (error) {
                                            console.error("เกิดข้อผิดพลาด:", error);
                                            alert("เกิดข้อผิดพลาดในการอัพโหลดไฟล์");
                                        }
                                    }}
                                >
                                    อัพโหลดไฟล์
                                </Button></>
                        )}
                           {confirm_payment == 1 ? (
                        <p style={{ marginTop: "20px", color: "green", fontWeight: "bold" }}>
                            ยืนยันการรับเงินแล้ว
                        </p>
                    ) : (
                        <p style={{ marginTop: "20px", color: "red", fontWeight: "bold" }}>
                            ยังไม่ได้ยืนยันการรับเงิน
                    </p>
                    )}
                    </Box>
                </Modal>
                <Modal
                open={open_slip_role_fac}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 2 }}
            >
                <Box sx={style_assign}>
                    <IconButton
                        style={{ position: "absolute", top: "0", right: "0" }}
                        onClick={() => setOpenslipRoleFac(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <h3>หลักฐานการโอนเงิน</h3>
                    {img ? (
                        <img
                            src={img}
                            alt="หลักฐานการโอนเงิน"
                            style={{ width: "100%", height: "auto", marginTop: "20px" }}
                        />
                    ) : (
                        <p style={{ marginTop: "20px", color: "red" }}>ไม่มีหลักฐานการโอนเงิน</p>
                    )}
                    {confirm_payment == 1 ? (
                        <p style={{ marginTop: "20px", color: "green", fontWeight: "bold" }}>
                            ยืนยันการรับเงินแล้ว
                        </p>
                    ) : (
                        <p style={{ marginTop: "20px", color: "red", fontWeight: "bold" }}>
                            ยังไม่ได้ยืนยันการรับเงิน
                    </p>
                    )}
                </Box>
            </Modal>
                <Table
                    rows={order_list}
                    columns={columns}
                />
            </Layout>
        )
    }

    export default HistoryDetail