import Layout from "../../components/layout"
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import WarehouseTable from "../../components/table/warehouse-table";
import { redirect } from "react-router-dom";
import { useEffect, useState } from "react";
import { callService } from "../../services/service";
import { useNavigate, useLocation } from 'react-router-dom'
import ProcessPopup from "../../components/warehouse/popup";
import moment from "moment";




const WarehousePage = () => {
    const [list_batch, setListBatch] = useState([])
    const [open, setOpen] = useState(false)
    const [list_product, setListProduct] = useState([])
    const [material_selected, setMaterialSelected] = useState(null)
    const [material_quantity, setMaterialQuantity] = useState(0)
    const [process_quantity, setProcessQuantity] = useState(0)
    const [product, setProduct] = useState(null)
    const [search_text, setSearchText] = useState('')

    const token = localStorage.getItem('accessToken')
    const user_id = localStorage.getItem('user_id')
    const role_id = localStorage.getItem('role_id')
    const product_id = localStorage.getItem('product_id')
    

    let navigate = useNavigate()



    useEffect(() => {
        fetchBatch()
        loadProduct()

    }, [])

    const loadProduct = () => {

        callService('/material/list', { product_id: product_id }, token)
            .then(res => {
                let response = res.data.data
                let list = []
                response.map((item) => {
                    list.push({
                        'id': item.attribute_id,
                        'product': item.prop,
                        'product_id': item.product_id
                    })
                })
                console.log(product_id)
                setListProduct(list)
            }).catch((error) => {
                if (error.response.status === 403) {
                    navigate('/login')
                }
            })
    }

    const fetchBatch = () => {
        callService('/batch/getByUserId', { 'user_id': user_id, search: search_text }, token)
            .then(res => {
                let list = []
                let result = res.data.data
                result.map((data,index) => {
                    list.push({
                        'id': data.id,
                        "index": index + 1,

                        'batch_no': data.batch_no,
                        'product': data.product,
                        'create_date': moment(data.create_date).format('DD MMM YYYY'),
                        'quantity': data.remain_quantity,
                        'unit': data.unit

                    })
                })
                setListBatch(list)
                console.log(setListBatch)
            }).catch(error => {
                let response = error.response
                if (response.status === 403) {
                    navigate('/login')
                }
            })

    }

    const handleNewOrders = () => {
        navigate('/orders')
    }
    const handleAddOrders = () => {
        navigate('/warehouse/add')
    }


    const handleClosePopup = () => {
        setOpen(false)
    }

    const handleProcessClick = (row) => {
        setMaterialSelected(row)
        setOpen(true)

    }

    const handleConfirmClick = () => {
        let payload = {
            'old_quantity': material_quantity,
            'process_quantity': process_quantity,
            'old_batch_no': material_selected.batch_no,
            'product_id': product.product_id,
            'attribute_id': product.id
        }

        callService('/batch/process', payload, token)
            .then((res) => {
                setOpen(false)
                fetchBatch()
            }).catch(res => {
                if (res.response.status === 403) {
                    navigate('/login')
                }
            })
    }

    const handleSearchClick = () => {
        fetchBatch()
    }

    return (
        <Layout title='คลังสินค้า'>
            <Box my={2} >
                <div style={{ margin: 12 }}>
                    <div style={{ marginLeft: 12, marginRight: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {role_id === '2' || role_id === '3' && (
                            <>
                                <Button
                                    variant="contained"
                                    sx={{ backgroundColor: '#15322D', color: 'white', fontSize: 20 }}
                                    startIcon={<AddBoxOutlinedIcon />}
                                    onClick={handleNewOrders}
                                    // style={{ padding: '5px' }}
                                >
                                    สร้างรายการสั่งซื้อสินค้าใหม่
                                </Button>
                            </>
                        )}
                        {role_id === '4' && (
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: '#15322D', color: 'white', fontSize: 20 }}
                                startIcon={<AddBoxOutlinedIcon />}
                                onClick={handleAddOrders}
                                // style={{ padding: '10px' }}
                            >
                                เพิ่มสินค้าเข้าคลัง
                            </Button>
                        )}
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '10px' }}>
                            <TextField
                                id="search"
                                name="search"
                                style={{ width: '490px', padding: '5px' }}
                                onChange={(event) => setSearchText(event.target.value)}
                            />
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#15322D', color: 'white', fontSize: 20, '&:hover': {
                                        backgroundColor: '#051a16',
                                    }
                                }}
                                startIcon={<SearchIcon />}
                                style={{ padding: '10px' }}
                                onClick={handleSearchClick}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                    <div style={{ paddingTop: '12px', padding: '10px' }}>
                        <WarehouseTable
                            rows={list_batch}
                            role_id ={role_id}
                            onProcess={handleProcessClick}
                        />
                    </div>
                    <ProcessPopup
                        open={open}
                        onClose={handleClosePopup}
                        listProduct={list_product}
                        material_data={material_selected}
                        setMaterialQuantity={setMaterialQuantity}
                        setProcessQuantity={setProcessQuantity}
                        setProduct={setProduct}
                        onConfirm={handleConfirmClick}
                    />
                </div>
            </Box>
        </Layout>
    )
}
export default WarehousePage