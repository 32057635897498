import React, { useState } from "react";
import { Button, Typography, Container, Box, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import VendorTable from "../../components/table/vendor-table";
import Layout from "../../components/layout";
import { addFriendVendor, deleteVendor, getVendorList, searchVendor } from "../../services/vendor";
import { createUser, createRole,createFarmerDetail } from "../../services/profile";

import { useNavigate } from "react-router-dom"

const ProductList = () => {
  const [vendorList, setVendorList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [add_vendor_data, setVendorData] = useState({
    user_id: null,
    vendor_id: null,
    factory_id: null
  })
  const [dialogContent, setDialogContent] = useState()
  const [modeDialog, setModeDialog] = useState('Add')
  const navigate = useNavigate()
  const user_id = localStorage.getItem('user_id');
  const token = localStorage.getItem('accessToken')
  const role_id = localStorage.getItem('role_id')
  const product_id = localStorage.getItem('product_id')
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [formValues, setFormValues] = useState({
    username: '',
    password: '',
    firstName: '',
    lastName: '',
    phone: '',
    address: ''
  });
  const handleSearch = () => {
    let payload = {
      'user_id': user_id,
      'role_id': role_id,
      'searchPara': searchText,
    }
    searchVendor(payload, token)
      .then(res => {
        let list = res.data.data.map((item, index) => ({
          index: index + 1,
          id: item.id,
          user_id: item.user.id,
          ownner_name: `${item.user.firstname} ${item.user.lastname}`,
          vendor_name: item.name,
          type: item.detail_type,
          tel_no: item.user.tel_no,
          product: item.detail_2,
          status: item.relation ? 'เพิ่มแล้ว' : 'ยังไม่เพิ่ม',
        }));
        setVendorList(list);
      }).catch(error => {
        console.log(error.response)
        if (error.response.status === 403) {
          navigate('/login')
        }
      })
  };

  const handleDetailOpen = (id) => {
    window.location.href = '/vendor_management/detail/' + id
  }

  const handleAddFriend = (row) => {
    setVendorData({
      user_id: user_id,
      vendor_id: row.user_id,
      detail_id: row.id,
      type: role_id
    })
    setModeDialog("Add")
    setDialogContent('ยืนยันการเพิ่ม คู่ค้า นี้ใช่หรือไม่?')
    setDialogOpen(true);

  };

  const handleDelete = (row) => {
    setVendorData({
      user_id: user_id,
      vendor_id: row.user_id,
      detail_id: row.id
    })
    setModeDialog("Remove")
    setDialogContent('ยืนยันการลบ คู่ค้า นี้ใช่หรือไม่?')
    setDialogOpen(true);
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmAddFriend = () => {
    if (modeDialog === "Add") {
      addFriendVendor(add_vendor_data, token)
        .then(res => {
          if (res.status === 200) {
            setDialogOpen(false);
            loadVendorList()
          }
        }).catch(error => {
          console.log(error)
        })
    } else {

      deleteVendor(add_vendor_data, token)
        .then(res => {
          if (res.status === 200) {
            setDialogOpen(false);
            loadVendorList()
          }
        }).catch(error => {
          console.log(error)
        })
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSubmit = () => {
    console.log(formValues);

    let payload_create_user = {
      "username": formValues.username,
      "firstname": formValues.firstName,
      "lastname": formValues.lastName,
      "password": formValues.password,
      "tel_no": formValues.phone,
      "address": formValues.address,
      "create_by": 0,
      'product_id': 1
    }
    createUser(payload_create_user, token)
      .then(res => {
        if (res.status === 200) {
          let data = res.data.data
          let payload_create_role = {
            user_id: data.id,
            role_id: 4
          }
          createRole(payload_create_role, token).then(res => {
            if (res.status === 200) {
              let payload = {
                "user_id": data.id,
                "name_th": formValues.firstName,
                "surname_th": formValues.lastName,
                "product_id": 1,
                "address": data.address
              }
              createFarmerDetail(payload, token).then(res => {
                console.log(res)
                if (res.status === 200) {
                  let payload_vender ={
                    user_id: user_id,
                    vendor_id: data.id,
                    detail_id: res.data.data.id,
                    type: 3
                  }
                  addFriendVendor(payload_vender, token)
                  .then(res => {
                    if (res.status === 200) {
                      alert('เพิ่มเกษตรกรสำเร็จ')
                      loadVendorList()
                    }
                  }).catch(error => {
                    console.log(error)
                  })

                }

              })

            }

          })
        }
      }).catch(error => {
        console.log(error)
      })
    handleClose();
  };
  const loadVendorList = () => {
    let payload = {
      user_id: user_id,
      role_id: role_id,
      product_id: product_id
    }

    getVendorList(payload, token)
      .then(res => {
        let data = res.data.data
        let list = []
        console.log(res.data.data)

        data.map((item, index) => {
          list.push({
            index: index + 1,
            id: item.id,
            user_id: item.user.id,
            ownner_name: `${item.user.firstname} ${item.user.lastname}`,
            vendor_name: item.name,
            tel_no: item.user.tel_no,
            product: item.description,
            status: item.relation ? 'เพิ่มแล้ว' : 'ยังไม่เพิ่ม',
          })
        });
        console.log(list)
        setVendorList(list);
      })
      .catch(error => {
        if (error.response.status === 403) {
          navigate('/login')
        }
      });
  };

  React.useEffect(() => {
    loadVendorList();
  }, []);

  return (
    <Layout title="รายการคู่ค้า">
      <Box display="flex" justifyContent="space-between" alignItems="center" my={3}>
      {role_id == 3 && (
        <Button
          variant="contained"
          onClick={handleOpen} // ใส่ฟังก์ชันสำหรับเพิ่มเกษตรกร
          sx={{
            backgroundColor: '#2A9D8F',
            color: 'white',
            fontSize: 22,
            px: 4,
            ":hover": {
              backgroundColor: '#1F776E'
            }
          }}
        >
          เพิ่มเกษตรกร
        </Button>
      )}
        <Box display="flex" alignItems="center">
          <TextField
            id="search"
            variant="outlined"
            sx={{
              width: 500,
            }}
            value={searchText}
            placeholder="ค้นหาจากชื่อ/เบอร์โทร"
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={handleSearch}
            startIcon={<SearchIcon />}
            sx={{
              backgroundColor: '#15322D',
              color: 'white',
              fontSize: 22,
              marginLeft: 2,
              px: 4,
              ":hover": {
                backgroundColor: '#102723'
              }
            }}
          >
            ค้นหา
          </Button>
        </Box>
      </Box>


      <Box my={2}>
        <VendorTable rows={vendorList} handleAddFriend={handleAddFriend} handleDetailOpen={handleDetailOpen} handleDelete={handleDelete} />
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>เพิ่มเกษตรกร</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="username"
            label="Username"
            type="text"
            fullWidth
            value={formValues.username}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="password"
            label="Password"
            type="text"
            fullWidth
            value={formValues.password}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="firstName"
            label="ชื่อ"
            type="text"
            fullWidth
            value={formValues.firstName}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="lastName"
            label="นามสกุล"
            type="text"
            fullWidth
            value={formValues.lastName}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="phone"
            label="เบอร์โทร"
            type="tel"
            fullWidth
            value={formValues.phone}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="address"
            label="ที่อยู่"
            type="text"
            fullWidth
            value={formValues.address}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            ยกเลิก
          </Button>
          <Button onClick={handleSubmit} color="primary">
            บันทึก
          </Button>
        </DialogActions>
      </Dialog>
      {/* Custom Material-UI Dialog for adding friend */}
      <Dialog style={{}} open={isDialogOpen} onClose={handleDialogClose}>
        <DialogContent style={{ backgroundColor: "#F9FCF3", width: 500, height: 50 }}>
          <DialogContentText style={{ fontSize: 26, textAlign: 'center' }}>
            {dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button variant="contained" style={{ backgroundColor: "#B5D86C", color: "#15322D", fontSize: 26, width: 150 }} onClick={handleConfirmAddFriend} >
            ใช่
          </Button>
          <Button variant="contained" style={{ backgroundColor: "#E7202C", fontSize: 26, width: 150 }} onClick={handleDialogClose} >
            ไม่
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>

  );
};

export default ProductList;




