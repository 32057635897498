import { useEffect, useState, React, Fragment } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { getOrdersById, orderUpdateConfirmSlip } from "../../../../services/orders"
import { Box, Typography, Card, CardContent, Dialog, Divider, IconButton, Button, Grid } from "@mui/material";
import Layout from "../../../../components/layout_mobile"
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from "moment"

const HistoryDetail = () => {

    const { order_id } = useParams()
    const [open_dialog, setOpenDialog] = useState(false)
    const [open_slip, setOpenslip] = useState(false)
    const [open, setOpen] = useState(false);
    const [orderData, setOrders] = useState()

    const [open_slip_role_fac, setOpenslipRoleFac] = useState(false)

    const [img, setIMG] = useState(null)
    let token = localStorage.getItem('accessToken')
    let role_id = localStorage.getItem('role_id')
    let user_id = localStorage.getItem('user_id')
    const [confirm_payment, setConfirmPayment] = useState(null)
    const [farmer_order_id, setFarmerOrderId] = useState('')

    let navigate = useNavigate()
    useEffect(() => {
        fetchOrderData()

    }, [order_id, role_id, token, user_id, navigate]);
    const fetchOrderData = () => {
        getOrdersById(parseInt(order_id), token)
            .then(res => {
                let order = res.data.data
                console.log(order)
                setIMG(order.img_slip)
                setOrders(order)
            }).catch(error => {
                if (error.response.status === 403) {
                    navigate('/login')
                }
            })
    }


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        orderUpdateConfirmSlip({
            user_id: user_id,
            confirm_payment: 1,
            order_id: order_id
        }, token)
            .then((res) => {
                alert("ยืนยันสำเร็จ")
                window.location.reload();
                setOpenslip(true)
            }).catch(error => {
                console.log(error)
            })
        setOpen(false);
    };

    const handleCancel = () => {
        console.log("ยกเลิก");
        setOpen(false);
    };

    return (
        <Layout title='รายละเอียด'>
            <Modal
                open={open_slip}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 2 }}
                sx={{ padding: 1 }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "90%",
                        maxWidth: "600px",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: "8px",
                        overflow: "auto",
                        maxHeight: "90vh",
                    }}
                >
                    <IconButton
                        style={{ position: "absolute", top: "10px", right: "10px" }}
                        onClick={() => setOpenslip(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="subtitle1" gutterBottom>
                        หลักฐานการโอนเงิน
                    </Typography>
                    {img ? (
                        <img
                            src={img}
                            alt="หลักฐานการโอนเงิน"
                            style={{
                                width: "100%",
                                height: "auto",
                                marginTop: "20px",
                                borderRadius: "4px",
                            }}
                        />
                    ) : (
                        <p style={{ marginTop: "20px", color: "red" }}>ไม่มีหลักฐานการโอนเงิน</p>
                    )}
                    {img && (
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{
                                mt: 3, // ระยะห่างด้านบนจากรูปภาพ
                                textTransform: "none",
                            }}
                            onClick={() => {
                                const link = document.createElement("a");
                                link.href = img;
                                link.download = `หลักฐานการโอนเงิน${order_id}.jpg`;
                                link.click();
                            }}
                        >
                            ดาวน์โหลดไฟล์
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        color="error"
                        fullWidth
                        sx={{
                            mt: 3,
                            textTransform: "none",
                        }}
                        onClick={() => setOpenslip(false)}
                    >
                        ปิด
                    </Button>
                </Box>
            </Modal>


            <Box sx={{ padding: 1 }}>
                <Grid item xs={12}  >

                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography variant="subtitle1" gutterBottom>
                                <strong>เลขที่คำสั่งซื้อ:</strong> {orderData?.orders_no}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                <strong>ผู้สั่ง:</strong> {orderData?.user}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                <strong>วันที่สั่ง:</strong> {moment(orderData?.create_date).format('YYYY-MM-DD')}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: 'black', // สีของคำว่า "สถานะ"
                                }}
                            >
                                สถานะ:
                                <span
                                    style={{
                                        marginLeft: '4px',
                                        color:
                                            orderData?.status === 5
                                                ? 'red'
                                                : orderData?.status === 4
                                                ? 'green'
                                                : orderData?.status === 3
                                                ? 'blue'
                                                : orderData?.status === 2
                                                ? 'orange'
                                                : orderData?.status === 1
                                                ? 'gray' : 'gray'
                                    }}
                                >
                                    {orderData?.status === 5
                                        ? 'ยกเลิก'
                                        : orderData?.status === 4
                                            ? 'เสร็จสิ้น'
                                        : orderData?.status === 3
                                            ? 'กำลังจัดส่ง'
                                        : orderData?.status === 2
                                            ? 'กำลังดำเนินการ'
                                        : orderData?.status === 1
                                            ? 'รอดำเนินการ' : 'รอดำเนินการ'}
                                </span>
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                <strong>วันที่จัดส่งสำเร็จ:</strong>  {orderData?.delivery_date ? moment(orderData?.delivery_date).format('YYYY-MM-DD') : ''}
                            </Typography>

                            <Typography
                                variant="body2"
                                sx={{
                                    color: 'black',
                                }}
                            >
                                สถานะการรับเงิน :
                                <span
                                    style={{
                                        marginLeft: '4px',
                                        color:
                                            orderData?.confirm_payment === 1
                                                ? 'green'
                                                : 'red'
                                    }}
                                >
                                    {orderData?.confirm_payment === 1
                                        ? 'ยืนยันแล้ว'
                                        : 'ยังไม่ได้ยืนยัน'}
                                </span>
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant="subtitle1" gutterBottom>
                                <strong>สินค้า:</strong> {orderData?.product_name}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                <strong>จำนวน:</strong> {orderData?.total_weight} <strong>กิโลกรัม</strong>
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                <strong>หมายเหตุ:</strong> {orderData?.remark} 
                            </Typography>
                            <Divider sx={{ my: 2 }} />

                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={() => {
                                    setOpenslip(true)
                                }}
                                sx={{
                                    display: "block",
                                    mx: "auto", // จัดปุ่มให้อยู่ตรงกลาง
                                }}
                            >
                                ดูหลักฐานการโอนเงิน
                            </Button>
                            
                            <Button
                                onClick={() => {
                                    handleOpen(true)
                                }}
                                variant="outlined"
                                color="success"
                                fullWidth
                                disabled={orderData?.confirm_payment === 0 ?false:true}
                                sx={{
                                    marginTop: "5px",
                                    display: "block",
                                    mx: "auto", // จัดปุ่มให้อยู่ตรงกลาง
                                }}
                            >
                                {orderData?.confirm_payment === 0 ?
                                    'ยืนยันการรับเงิน':'ยืนยันการรับเงินแล้ว'
                                }
                                
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">{"ยืนยันการรับเงิน"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        คุณต้องการยืนยันการรับเงินหรือไม่?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        ยืนยัน
                    </Button>
                    <Button onClick={handleCancel} color="error">
                        ยกเลิก
                    </Button>
                </DialogActions>
            </Dialog>        </Layout>
    )
}

export default HistoryDetail
