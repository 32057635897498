import { DataGrid } from "@mui/x-data-grid"
import Box from '@mui/material/Box';

const Table = ({ columns, rows }) => {
    return (
        <Box sx={{ height: 600, width: "100%" }}>

        <DataGrid
            columns={columns}
            rows={rows}
            rowSelection={false}
            sx={{
                '& .MuiDataGrid-columnHeader': {
                    backgroundColor: '#15322D',
                    color: 'white',
                },
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    lineHeight: 1.5,
                },
                backgroundColor: '#C9D4C4',
                color: '#15322D',
                fontSize: 20
            }}
        />
                </Box>

    )
}

export default Table