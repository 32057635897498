import React from 'react';
import { Container, Typography, Grid, Button } from '@mui/material';
const ProfileView = ({ userData,handleEditButton }) => {
    return (
        <>
            <Container maxWidth="lg" sx={{ mt: 14, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <Grid container spacing={2} sx={{ border: '1px solid #ccc', borderRadius: 2 }}>

                        <React.Fragment>
                            <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                                <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                    ชื่อผู้ใช้
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                                <Typography variant="body2">
                                    {userData?.username}
                                </Typography>
                            </Grid>
                        </React.Fragment>

                        {/* <React.Fragment>
                            <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                                <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                    รหัสผ่าน
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                                <Typography variant="body2">
                                    ********
                                </Typography>
                            </Grid>
                        </React.Fragment> */}

                        <React.Fragment>
                            <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                                <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                    เบอร์ติดต่อ
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                                <Typography variant="body2">
                                   {userData?.tel_no}
                                </Typography>
                            </Grid>
                        </React.Fragment>

                        <React.Fragment>
                            <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                                <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                    ขื่อ-นามสกุล
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                                <Typography variant="body2">
                                   {userData?.firstname} {userData?.lastname}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                        <React.Fragment>
                            <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                                <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                  Email
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                                <Typography variant="body2">
                                   {userData?.email}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                        <React.Fragment>
                            <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                                <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                   ที่อยู่
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                                <Typography variant="body2">
                                   {userData?.address}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                        <React.Fragment>
                            <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                                <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                   ชื่อบริษัท
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                                <Typography variant="body2">
                                   {userData?.company_name}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                        <React.Fragment>
                            <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                                <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                   เลขประจำตัวผู้เสียภาษี
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                                <Typography variant="body2">
                                   {userData?.tax_id}
                                </Typography>
                            </Grid>
                        </React.Fragment>

                        <React.Fragment>
                            <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                                <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                  ธนาคาร
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                                <Typography variant="body2">
                                   {userData?.bank_name}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                        <React.Fragment>
                            <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                                <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                   ชื่อ บัญชี ธนาคาร
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                                <Typography variant="body2">
                                   {userData?.bank_account}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                        <React.Fragment>
                            <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                                <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                   เลข บัญชี ธนาคาร
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                                <Typography variant="body2">
                                   {userData?.account_no}
                                </Typography>
                            </Grid>
                        </React.Fragment>



                </Grid>
            </Container>
            <Container maxWidth="lg" sx={{ mt: 2, textAlign: 'center', p: 5 }}>
                <Button variant="contained"
                    onClick={handleEditButton}
                    sx={{
                        backgroundColor: '#15322D',
                        fontSize: 20,
                        textTransform: 'none',
                        fontWeight: 400,
                        px: 25,
                        '&:hover': {
                            backgroundColor: '#112824', // Change to your desired color
                        },
                    }}>
                    แก้ไขข้อมูล
                </Button>
            </Container>
        </>
    )
}
export default ProfileView