import Table from "../table"
import { useEffect, useState } from "react";
import { Grid, Typography, Button,Tooltip, IconButton,Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {  } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { updateBatch } from "../../services/orders"

const WarehouseTable = ({ rows, onProcess, role_id }) => {
    const [open, setOpen] = useState(false);
    const [rows_select, setRowSelect] = useState();
    let token = localStorage.getItem('accessToken')

    const [selectedDate, setSelectedDate] = useState();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const DatePopup = (rows) => {
        setRowSelect(rows)
        setOpen(true)
    }
    const handleSave = (rows) => {
        console.log(rows_select)
        updateBatch({ id:rows_select.id,pack_date:selectedDate  }, token)
        .then(res => {
            alert('สำเร็จ')
        })
    }
    const handleDateChange = (newDate) => {
       let date = moment(newDate).format('YYY-MM-DD')
       setSelectedDate(date);
    };
    const columns = [
        { field: 'batch_no', headerName: 'หมายเลขชุด', flex: 0.2, align: 'center' },
        { field: 'product', headerName: 'ชื่อสินค้า', flex: 0.5, align: 'center' },
        { field: 'pack_date', headerName: 'วันที่แพ็คใส่ถุง', flex: 0.2, align: 'center' },
        // {
        //     field: 'edit', headerName: 'แก้ไข', flex: 0.2, align: 'center',


        //     renderCell: (params) => (
        //         <Tooltip title={<Typography>แก้ไข</Typography>}>
        //             <IconButton color="primary" onClick={() => DatePopup(params.row)}>
        //                 <ModeEditIcon sx={{ color: '#15322D', fontSize: 35 }} />
        //             </IconButton>
        //         </Tooltip>
        //     )
        // },

        { field: 'quantity', headerName: 'จำนวน', flex: 0.2, align: 'center' },
        ...(role_id != 3 ? [{
            field: 'change',
            headerName: 'แปรรูป',
            flex: 0.2,
            align: 'center',
            renderCell: (params) => (
                <Tooltip title={<Typography>แปรรูป</Typography>}>
                    <IconButton color="primary" onClick={() => onProcess(params.row)}>
                        <AutorenewIcon sx={{ color: '#15322D', fontSize: 35 }} />
                    </IconButton>
                </Tooltip>
            )
        }] : []),
        // {
        //     field: 'accept',
        //     headerName: 'รายละเอียด',
        //     flex: 0.2,
        //     align: 'center',
        //     renderCell: (params) => (
        //         <Tooltip title={<Typography>ข้อมูล</Typography>}>
        //             <IconButton color="primary" onClick={() => console.log('detail')}>
        //                 <SearchIcon sx={{ color: '#15322D', fontSize: 35 }} />
        //             </IconButton>
        //         </Tooltip>
        //     )
        // },
    ];


    const columns_2 = [
        {
            field: 'index',
            headerName: 'No',
            width: 0.1,
            headerAlign: 'center',
            align: 'center',
            // hide: true
        },
        // {
        //     field: 'No',
        //     headerName: 'No.',
        //     width: 100,
        //     headerAlign: 'center',
        //     align: 'center',
        //     renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,

        // },
        { field: 'product', headerName: 'ชื่อสินค้า', flex: 0.5, align: 'center' },
        { field: 'create_date', headerName: 'วันที่ผลิต', flex: 0.2, align: 'center' },
        { field: 'quantity', headerName: 'จำนวน', flex: 0.2, align: 'center' },
    ]
    return (
        <div style={{ height: 600, width: "100%" }}>
            <Table
                rows={rows}
                columns={role_id === '4' ? columns_2 : columns}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>

                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>วันที่แพ็คใส่ถุง</DialogTitle>
                    <DialogContent>
                    <Grid item xs={9}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        sx={{ width: '100%' }}
                                        format="DD MMM YYYY"
                                        onChange={(value) => handleDateChange(value)}
                                        value={selectedDate}
                                    />
                                </DemoContainer>
                                </LocalizationProvider>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>ยกเลิก</Button>
                        <Button onClick={handleSave} variant="contained">
                            บันทึก
                        </Button>
                    </DialogActions>
                </Dialog>
            </LocalizationProvider>
            );
        </div>
    )
}
export default WarehouseTable