import { useState } from 'react';
import {
    Container,
    Paper,
    Typography,
    TextField,
    Button,
    Box,
    InputAdornment,
    IconButton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FiUser, FiLock } from 'react-icons/fi';
import { login } from '../../services/authen';
import { jwtDecode } from 'jwt-decode';
import Logo from '../../img/logo512.png';

const LoginPage = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [isAuthorize, setAuthorize] = useState(true);
    const [loginForm, setLoginForm] = useState({ username: '', password: '' });

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setLoginForm((prevLoginForm) => ({
            ...prevLoginForm,
            [name]: value,
        }));
    };

    const handleLogin = () => {
        setAuthorize(true);
        login(loginForm)
            .then((res) => {
                if (res.status === 200) {
                    let token = res.data.data;
                    let user = jwtDecode(token);
                    if (user.role.role_id !== 4) {
                        alert('กรุณา เข้าสู่ระบบ ด้วยบัญชีเกษตรกร')
                    }else{
                        localStorage.setItem('accessToken', token);
                        localStorage.setItem('user_id', user.user_id);
                        localStorage.setItem('role_id', user.role.role_id);
                        localStorage.setItem('product_id', user.product_id);
                        localStorage.setItem('username', `${user.firstname} ${user.lastname}`);
                        window.location.href = '/orders/farmer';
                    }
                }
            })
            .catch((err) => {
                if (err.response?.status === 401) {
                    setAuthorize(false);
                }
            });
    };

    return (
        <div
            style={{
                backgroundColor: '#C9D4C4',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '16px',
            }}
        >
            <Container component="main" maxWidth="xs">
                <Paper
                    elevation={3}
                    style={{
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: '12px',
                        backgroundColor: '#F9FCF3',
                    }}
                >
                    <Box mb={2}>
                        <img
                            src={Logo}
                            alt="Logo"
                            style={{
                                maxWidth: '100%',
                                height: 'auto',
                                display: 'block',
                                margin: '0 auto',
                            }}
                        />
                    </Box>
                    <Typography variant="h6" sx={{ fontWeight: '300' }}>
                        เข้าสู่ระบบ
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: '400' }}>
                        เกษตรกร
                    </Typography>
                    <form style={{ width: '100%', marginTop: '20px' }}>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            name="username"
                            value={loginForm.username}
                            onChange={handleInputChange}
                            placeholder="username"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FiUser style={{ fontSize: '20px' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            variant="outlined"
                            margin="dense"
                            error={!isAuthorize}
                            fullWidth
                            value={loginForm.password}
                            onChange={handleInputChange}
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder="password"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FiLock style={{ fontSize: '20px' }} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {!isAuthorize && (
                            <Typography
                                sx={{ color: '#E7202C', fontWeight: 300, fontSize: '14px', marginTop: '8px' }}
                            >
                                รหัสผ่านไม่ถูกต้องโปรดลองอีกครั้ง
                            </Typography>
                        )}
                        <Box mt={3}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={handleLogin}
                                style={{
                                    textTransform: 'none',
                                    marginTop: '16px',
                                    backgroundColor: '#50a177',
                                    fontWeight: 300,
                                    height: '45px',
                                    fontSize: '16px',
                                    borderRadius: '8px',
                                }}
                            >
                                เข้าสู่ระบบ
                            </Button>
                        </Box>
                    </form>
                </Paper>
            </Container>
        </div>
    );
};

export default LoginPage;
