import Table from "../table"
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import { IconButton, Tooltip, Typography } from "@mui/material"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from "react"
import { Box, Button } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close";
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';

const OrdersTable = ({ rows, handleAccept, handleOpenDialog }) => {
    const [open_sent, setOpenSent] = useState(false)
    const [img_logis, setIMGLogis] = useState(null)
    let role_id = localStorage.getItem('role_id')

    const style_assign = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const showSent = (row) => {
        console.log(row)
        setIMGLogis(row.img_logis)
        setOpenSent(true)
    }
    const columns = [
        // { field: 'id', headerName: 'ID', width: 0, align: 'center' },
        { field: 'orders_no', headerName: 'เลขคำสั่งซื้อ', width: 200, align: 'center' },
        { field: 'orderer', headerName: 'ผู้สั่ง', width: 200, align: 'center' },
        { field: 'order_date', headerName: 'วันที่สั่ง', width: 200, align: 'center' },
        { field: 'status', headerName: 'สถานะ', width: 200, align: 'center' },

        { field: 'product', headerName: 'สินค้า', width: 200, align: 'center' },
        { field: 'brand', headerName: 'ยี่ห้อ', width: 100, align: 'center' },
        { field: 'quantity', headerName: 'จำนวน', width: 100, align: 'center' },
        { field: 'total_weight', headerName: 'น้ำหนักสุทธิ', width: 200, align: 'center' },
        { field: 'delivery_date', headerName: 'วันที่ส่งมอบ', width: 150, align: 'center' },
        {
            field: "accept",
            headerName: "ยอมรับคำสั่งซื้อ",
            width: 150,
            align: 'center',
            renderCell: (params) => {
                return params.row.status === 'กำลังจัดส่ง' ? (
                    <Tooltip title={<Typography>ยอมรับคำสั่งซื้อ</Typography>}>
                        <IconButton color="primary" onClick={() => handleAccept(params.row.id)}>
                            <HowToRegOutlinedIcon sx={{ color: '#15322D', fontSize: 40 }} />
                        </IconButton>
                    </Tooltip>
                ) : '-';
            },
        },
        {
            field: "info",
            headerName: "หมายเหตุ",
            width: 120,
            align: 'center',
            renderCell: (params) => (
                <Tooltip title={<Typography>หมายเหตุ</Typography>} >
                    <IconButton color="primary" onClick={() => handleOpenDialog(params.row.remark)}>
                        <InfoOutlinedIcon sx={{ color: '#15322D', fontSize: 40 }} />
                    </IconButton>
                </Tooltip>
            ),
        }
    ]
    if (role_id == '2') {
        columns.push(        {
            field: "sent",
            headerName: "รูปการจัดส่ง",
            width: 200,
            align: 'center',
            renderCell: (params) => (
                <Tooltip title={<Typography>รูปการจัดส่ง</Typography>} >
                    <IconButton color="primary" onClick={() => showSent(params.row)}>
                        <PhotoCameraBackIcon sx={{ color: '#15322D', fontSize: 40 }} />
                    </IconButton>
                </Tooltip>
            ),
        });
    }
    return (
        <div style={{ height: 600, width: "100%" }}>
            <Modal
                open={open_sent}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 2 }}
            >
                <Box sx={style_assign}>
                    <IconButton
                        style={{ position: "absolute", top: "0", right: "0" }}
                        onClick={() => setOpenSent(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <h3>รูปการจัดส่งสินค้า</h3>
                    {img_logis ? (
                        <img
                            src={img_logis}
                            alt="รูปการส่ง"
                            style={{ width: "100%", height: "auto", marginTop: "20px" }}
                        />


                    ) : (
                        <>
                            <h4>ไม่มีรูปการจัดส่งสินค้า</h4>

                        </>
                    )}
                </Box>
            </Modal>
            <Table
                columns={columns}
                rows={rows}
            />
        </div>
    )
}
export default OrdersTable