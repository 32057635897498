import { useEffect, useState } from "react"
import Layout from "../../../components/layout"
import HistoryTable from "../../../components/table/history-table"
import { getHistoryOrders } from "../../../services/orders"
import { useNavigate } from "react-router-dom"
import moment from "moment"

const HistoryPage = () => {
    const [history_list, setHistoryList] = useState([])
    const user_id = localStorage.getItem('user_id')
    const token = localStorage.getItem('accessToken')
    useEffect(() => {
        fetchHistoryData()
    }, [])
    const navigate = useNavigate()
    const fetchHistoryData = () => {
        getHistoryOrders({user_id : user_id},token)
            .then(res => {
                if (res.status === 200) {
                    let data = res.data.data
                    console.log(data)
                    let list = []
                    data.map(item => {
                        let attribute =item?.attribute[0]?.value ??''
                        list.push({
                            "id": item.id,
                            "orders_no": item.orders_no,
                            "orderer": item.user.firstname + ' ' + item.user.lastname,
                            "order_date": moment(item.create_date).format('DD MMM YYYY'),
                            "delivery_date" : item.delivery_date ? moment(item.delivery_date).format('DD MMM YYYY') : '' ,
                            "product": item.product.name+' '+attribute ,
                            "status": item.status_desc,
                            "quantity": item.total_weight + ' ' + item.product.unit,
                            "img_slip":item.img_slip,
                            "confirm_payment":item.confirm_payment

                        })
                    })
                    setHistoryList(list)
                }
            }).catch(error => {
                console.log(error)
                if (error.response.status === 403) {
                    navigate('/login')
                }
            })
    }
    return (
        <div>
            <Layout title='ประวัติคำสั่งซื้อ'>
                <HistoryTable 
                    rows={history_list}
                />
            </Layout>
        </div>
    )
}

export default HistoryPage