import { useEffect, useState } from "react"
import Layout from "../../../../components/layout"
import { getBatchforShipping, getOrdersById, shipping } from "../../../../services/orders"
import { useParams, useNavigate } from 'react-router-dom';
import moment from "moment";
import { Typography, TextField, Button } from '@mui/material'
import StockTable from "../../../../components/table/stock-table";
import { getCookie } from "../../../../utils/cookie";
import Swal from 'sweetalert2'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

const ShippingProduct = () => {
    const { orders_id } = useParams()
    const [orders, setOrders] = useState()
    const [list_stock, setStock] = useState([])
    const [current_quantity, setCurrentQuantity] = useState(0)
    const [selectedIds, setSelectedIds] = useState([])
    const user_id = localStorage.getItem('user_id')
    const role_id = localStorage.getItem('role_id')
    const token = localStorage.getItem('accessToken')

    useEffect(() => {
        fetchOrderById()

    }, [])

    const fetchOrderById = () => {
        getOrdersById(orders_id, token)
            .then(res => {
                if (res.status === 200) {
                    let order = res.data.data
                    setOrders(order)
                    fetchBatch(order.product_name)
                }
            }).catch(error => {
                console.log(error.response)
                if (error.response.status === 403) {
                    navigate('/login')
                }
            })
    }

    const fetchBatch = (product) => {
        let payload = {
            'user_id': user_id,
            'product': product
        }
        console.log(product)
        getBatchforShipping(payload, token)
            .then(res => {
                let data = res.data.data
                let list = []
                data.map(item => {
                    list.push({
                        'id': item.id,
                        'batch_no': item.batch_no,
                        'product': item.product,
                        'create_date': moment(item.create_date).format('DD MMM YYYY'),
                        'quantity': item.remain_quantity,
                        'order_quantity': 0
                    })
                })
                setStock(list)
            }).catch(error => {
                console.log(error.response)
                if (error.response.status === 403) {
                    navigate('/login')
                }
            })
    }

    const navigate = useNavigate()

    const handleOrderClick = () => {
        if (role_id !== '4') {
            navigate('/orders', { state: { order_id: orders_id,product_id : orders?.product_id, product: orders?.product_name, mode: 1 } })
        }
        else {
            navigate('/warehouse/add', { state: { order_id: orders_id, product: orders?.product_name, mode: 2 } })
        }
    }

    const handleConfirmClick = () => {

        if (current_quantity !== orders.total_weight) {
            alert('ปริมาณไม่ตรงกับคำสั่งซื้อ')
        }
        else {
            Swal.fire({
                title: "ยืนยันการจัดส่งสินค้าใช่หรือไม่",
                showCancelButton: true,
                confirmButtonText: "ยืนยัน",
                confirmButtonColor: '#B5D86C',
                cancelButtonText: "ยกเลิก",
                cancelButtonColor: '#E7202C'
            }).then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        'user_id': user_id,
                        'order_id': orders_id,
                        'batch': selectedIds,
                    }
                    shipping(payload, token)
                        .then(res => {
                            if (res.status === 200) {
                                window.location.href = '/orders/shipping'
                            }
                        }).catch(error => {
                          
                            if (error.response.status === 403) {
                                navigate('/login')
                            }
                        })
                } else {

                }
            })
        }
    }

    return (
        <Layout title='ส่งสินค้า'>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', color: "#15322D", fontWeight: '200' }}>

                <Typography sx={{ bgcolor: '#B5D86C', borderRadius: '12px', padding: '12px', marginRight: '10px' }}>
                    <Typography sx={{ padding: 3, fontSize: 25 }} component="span">{orders?.orders_no}</Typography>
                    <Typography sx={{ padding: 3, fontSize: 25 }} component="span">{orders?.user}</Typography>
                    <Typography sx={{ padding: 3, fontSize: 25 }} component="span">{moment(orders?.create_date).format('DD MMM YYYY')}</Typography>
                    <Typography sx={{ padding: 3, fontSize: 25 }} component="span">{orders?.product_name}</Typography>
                    <Typography sx={{ padding: 3, fontSize: 25 }} component="span">{orders?.total_weight}</Typography>
                    <Typography sx={{ padding: 3, fontSize: 25 }} component="span">{moment().format('DD MMM YYYY')}</Typography>
                </Typography>

                <TextField
                    id=""
                    label=""
                    value={current_quantity}
                    InputProps={{
                        readOnly: true,
                        style: { color: orders?.total_weight !== current_quantity ? 'red' : 'black', fontSize: 20 }
                    }}
                    sx={{ padding: 2 }}
                />
                <Button sx={{ paddingX: 5, fontSize: 25, backgroundColor: '#B5D86C', color: "#15322D" }} variant="contained" onClick={handleConfirmClick} >
                    ยืนยัน
                </Button>
            </div>
            <div style={{ paddingBottom: 12 }}>
                <Button
                    variant="contained"
                    sx={{ backgroundColor: '#15322D', color: 'white', fontSize: 25 }}
                    startIcon={<AddBoxOutlinedIcon />}
                    onClick={handleOrderClick}
                >
                    {role_id === '3' ? 'เพิ่มสินค้าเข้าคลัง' : 'เพิ่มสินค้าใหม่ / สั่งซื้อสินค้าเพิ่ม'}
                </Button>
            </div>
            <StockTable rows={list_stock} total={current_quantity} setTotal={setCurrentQuantity} setRows={setStock} selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
        </Layout>
    )
}
export default ShippingProduct