import { useEffect, useState } from "react"
import Layout from "../../../components/layout_mobile"
import HistorySalesTable from "../../../components/table/history-farmer-table"
import { getHistoryOrders, getHistorySalesOrders } from "../../../services/orders"
import { useNavigate } from "react-router-dom"
import moment from "moment"

const HistoryPage = () => {
    const [history_list, setHistoryList] = useState([])
    const user_id = localStorage.getItem('user_id')
    const token = localStorage.getItem('accessToken')
    useEffect(() => {
        fetchHistoryData()
    }, [])
    const navigate = useNavigate()
    const fetchHistoryData = () => {
        getHistorySalesOrders({ user_id: user_id }, token)
            .then(res => {
                if (res.status === 200) {
                    let data = res.data.data
                    let list = []
                    data.map(item => {
                        list.push({
                            "id": item.id,
                            "orders_no": item.orders_no,
                            "orderer": item.user.firstname + ' ' + item.user.lastname,
                            "order_date": moment(item.create_date).format('YYYY-MM-DD'),
                            "delivery_date": item.delivery_date ? moment(item.delivery_date).format('YYYY-MM-DD') : '',
                            "product": item.product.name,
                            "status": item.status,
                            "status_desc": item.status_desc,
                            "quantity": item.total_weight + ' ' + item.product.unit,
                            "img_slip": item.img_slip,
                            "confirm_payment": item.confirm_payment
                        })
                    })
                    list.sort((a, b) => {
                        return new Date(b.order_date) - new Date(a.order_date);
                    });
                    setHistoryList(list)
                }
            }).catch(error => {
                console.log(error)
                if (error.response.status === 403) {
                    navigate('/login')
                }
            })
    }

    return (
        <div>
         <Layout title={'ประวัติการขายสินค้า'}>
    {history_list.length === 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <p>ยังไม่มีประวัติขายสินค้า</p>
        </div>
    ) : (
        <HistorySalesTable 
            rows={history_list}
        />
    )}
</Layout>

        </div>
    )
}

export default HistoryPage