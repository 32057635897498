import React, { useEffect, useState } from 'react';
import { Grid, Typography, Container, TextField, Box, Button, Autocomplete, InputAdornment, OutlinedInput, MenuItem, Select } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getBrandByProduct } from '../../services/orders';


const NewOrders = ({list_vendor_relations, list_product, handleInputChange, orders_form, handleOrdersClick, handleConfirmClick, handleBack, confirmMode }) => {
    const [list_brand, setListBrand] = useState([]);
    const [piece, setPiece] = useState('');
    const [weightPerPiece, setWeightPerPiece] = useState('');

    const token = localStorage.getItem('accessToken');
    const role_id = localStorage.getItem('role_id');

    const handleProductChange = (data) => {
        if (role_id === "1") {
            if (data) {
                let product_id = data.product_id;
                getBrandByProduct({ product_id }, token)
                    .then(res => {
                        if (res) {
                            let data = res.data.data;
                            let list = [];
                            data.map(item => {
                                list.push({
                                    "id": item.id,
                                    "name": item.brand_name
                                });
                            });
                            setListBrand(list);
                        }
                    });
                handleInputChange({ name: 'product', value: data });
            }
        } else {
            handleInputChange({ name: 'product', value: data });
        }
    };

    useEffect(() => {
        if (orders_form.total_weight) {
            const quantity = parseFloat(orders_form.total_weight);
            handleInputChange({ name: 'quantity', value: quantity });
            handleInputChange({ name: 'weight_per_piece', value: 1 });

        } else {
            handleInputChange({ name: 'weight_per_piece', value: '' });

            handleInputChange({ name: 'quantity', value: '' });

        }
    }, [orders_form.total_weight, orders_form.weight_per_piece]);


    return (
        <Container>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '10vh',
                }}
            >
                <form>
                    <Grid container spacing={3} alignItems="center" sx={{ textAlign: 'center', marginTop: 12 }}>
                        <Grid item xs={3}>
                            <Typography variant="body1" sx={{ backgroundColor: '#15322D', color: 'white', p: 2, borderRadius: '10px' }} >
                                สินค้า / spec (SKU)
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-product"
                                options={list_product}
                                value={orders_form.product}
                                getOptionLabel={(option) => option.product}
                                disabled={confirmMode}
                                fullWidth
                                onChange={(event, data) => {
                                    handleProductChange(data);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Grid>
                        {role_id === "1" && (
                            <>
                                <Grid item xs={3}>
                                    <Typography variant="body1" sx={{ backgroundColor: '#15322D', color: 'white', p: 2, borderRadius: '10px' }} >
                                        เลือก Brand
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Select
                                        fullWidth
                                        disabled={confirmMode}
                                        sx={{ textAlign: 'left' }}
                                        value={orders_form.brand || ''}
                                        onChange={(event) => handleInputChange({ name: 'brand', value: event.target.value })}
                                    >
                                        {list_brand.length > 0 ? (
                                            list_brand.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem
                                                disabled
                                                value=""
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '50px', // กำหนดความสูงเพื่อให้ข้อความอยู่กลางแนวตั้ง
                                                }}
                                            >
                                                No options
                                            </MenuItem>
                                        )}
                                    </Select>

                                </Grid>
                            </>
                        )}

                        < Grid item xs={3}>
                            <Typography variant="body1" sx={{ backgroundColor: '#15322D', color: 'white', p: 2, borderRadius: '10px' }} >
                                เลือก vendor
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={list_vendor_relations}
                                fullWidth
                                disabled={confirmMode}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, data) => {
                                    handleInputChange({ name: 'vendor', value: data })
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Grid>

                        <Grid hidden={true} item xs={3}>
                            <Typography variant="body1" sx={{ backgroundColor: '#15322D', color: 'white', p: 2, borderRadius: '10px' }} >
                                น้ำหนักต่อชิ้น
                            </Typography>
                        </Grid>
                        <Grid hidden={true} item xs={9}>
                            <OutlinedInput
                                variant="outlined"
                                fullWidth
                                inputProps={{ min: 0 }}

                                disabled={confirmMode}
                                value={orders_form.weightPerPiece}
                                endAdornment={<InputAdornment position="end">{orders_form.product?.unit}</InputAdornment>}
                                name="weightPerPiece"
                                onChange={(event) => handleInputChange({ name: 'weight_per_piece', value: event.target.value })}
                                type='number'
                            />
                        </Grid>

                        <Grid hidden={true} item xs={3}>
                            <Typography variant="body1" sx={{ backgroundColor: '#15322D', color: 'white', p: 2, borderRadius: '10px' }} >
                                จำนวน
                            </Typography>
                        </Grid>
                        <Grid hidden={true} item xs={9}>
                            <OutlinedInput
                                variant="outlined"
                                fullWidth
                                inputProps={{ min: 0 }}
                                disabled={confirmMode}
                                value={orders_form.total_weight}
                                endAdornment={<InputAdornment position="end">ชิ้น</InputAdornment>}
                                name="quantity"
                                onChange={(event) => handleInputChange({ name: 'quantity', value: event.target.value })}
                                type='number'
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="body1" sx={{ backgroundColor: '#15322D', color: 'white', p: 2, borderRadius: '10px' }} >
                                น้ำหนัก
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <OutlinedInput
                                variant="outlined"
                                fullWidth
                                min="0"
                                disabled={confirmMode}
                                value={orders_form.total_weight}
                                endAdornment={<InputAdornment position="end">{orders_form.product?.unit}</InputAdornment>}
                                name="totalWeight"
                                onChange={(event) => handleInputChange({ name: 'total_weight', value: event.target.value })}
                                type='number'
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="body1" sx={{ backgroundColor: '#15322D', color: 'white', p: 2, borderRadius: '10px' }} >
                                กำหนดส่ง
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        sx={{ width: '100%' }}
                                        disabled={confirmMode}
                                        onChange={(value) => handleInputChange({ name: 'due_date', value: value })}
                                        value={orders_form.due_date}
                                        format="DD MMM YYYY"
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="body1" sx={{ backgroundColor: '#15322D', color: 'white', p: 2, borderRadius: '10px' }} >
                                หมายเหตุ
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                disabled={confirmMode}
                                name="remark"
                                onChange={(e) => handleInputChange({ name: 'remark', value: e.target.value })}
                            />
                        </Grid>

                        {!confirmMode ? (
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        px: 20,
                                        fontSize: 25,
                                        fontWeight: 500,
                                        backgroundColor: "#15322D",
                                        ":hover": {
                                            backgroundColor: '#15322D',
                                            color: 'white'
                                        }
                                    }}
                                    onClick={handleOrdersClick}
                                >
                                    สั่งซื้อ
                                </Button>
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            px: 10,
                                            fontSize: 25,
                                            fontWeight: 500,
                                            backgroundColor: "#C9D4C4",
                                            color: "#15322D",
                                            ":hover": {
                                                backgroundColor: '#15322D',
                                                color: 'white'
                                            }
                                        }}
                                        onClick={handleBack}
                                    >
                                        กลับไปแก้ไข
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            px: 14,
                                            fontSize: 25,
                                            backgroundColor: "#D5E9AC",
                                            fontWeight: 500,
                                            color: '#15322D',
                                            ":hover": {
                                                backgroundColor: '#15322D',
                                                color: 'white'
                                            },
                                        }}
                                        onClick={handleConfirmClick}
                                    >
                                        ยืนยัน
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </form>
            </Box>
        </Container >
    );
};

export default NewOrders;
