import axios from "axios"
const config = require('../config.js')
const API_URL = config.API_URL

export const getProducts = (token) => {
   return axios.post(`${API_URL}/products`,{}, {
      headers: {
         Authorization: `Bearer ${token}`
      }
   })
}

export const getMaterials = (payload, token) => {
   return axios.post(`${API_URL}/material/list`, payload, {
      headers: {
         Authorization: `Bearer ${token}`
      }
   })
}
export const saveOrders = (payload, token) => {
   return axios.post(`${API_URL}/orders/save`, payload, {
      headers: {
         Authorization: `Bearer ${token}`
      }
   })
}
export const saveOrdersAgent = (payload, token) => {
   return axios.post(`${API_URL}/orders/agent/save`, payload, {
      headers: {
         Authorization: `Bearer ${token}`
      }
   })
}
export const saveMaterialOrders = (payload, token) => {
   return axios.post(`${API_URL}/orders/save_material`, payload, {
      headers: {
         Authorization: `Bearer ${token}`
      }
   })
}

export const getListOrdersByUser = (user_id,token ) => {
   return axios.post(`${API_URL}/orders/getList`, { user_id: user_id }, {
      headers : {
         Authorization : `Bearer ${token}`
      }
   })
}
export const getListOrdersPaymentByUser = (user_id,token ) => {
   return axios.post(`${API_URL}/orders/getListPayment`, { user_id: user_id }, {
      headers : {
         Authorization : `Bearer ${token}`
      }
   })
}
export const getOrdersByVendor = (vendor_id, token) => {
   return axios.post(`${API_URL}/orders/getByVendor`, { vendor_id: vendor_id }, {
      headers : {           
         Authorization : `Bearer ${token}`
      }
   })
}

export const getOrdersById = (order_id,token) => {
   return axios.post(`${API_URL}/orders/getById`, { order_id: order_id }, {
      headers : {
         Authorization : `Bearer ${token}`
      }
   })
}

export const getBatchforShipping = (payload, token) => {
   return axios.post(`${API_URL}/batch/getForShipping`, payload, {
      headers : {
         Authorization : `Bearer ${token}`
      }
   })
}

export const shipping = (payload, token) => {
   return axios.post(`${API_URL}/orders/get_shipping`, payload, {
      headers : {
         Authorization : `Bearer ${token}`
      }
   })
}
export const orderStatusChange = (payload, token) => {

   return axios.post(`${API_URL}/orders/update_status`, payload, {
      headers : {
         Authorization : `Bearer ${token}`
      }
   })
}
export const orderUpdateSlip = (payload, token) => {
   console.log(payload)
   return axios.post(`${API_URL}/orders/update_slip`, payload, {
      headers : {
         Authorization : `Bearer ${token}`
      }
   })
}
export const orderUpdateLogistics = (payload, token) => {
   return axios.post(`${API_URL}/orders/update_logistics`, payload, {
      headers : {
         Authorization : `Bearer ${token}`
      }
   })
}
export const orderUpdateConfirmSlip = (payload, token) => {
   console.log(payload)
   return axios.post(`${API_URL}/orders/confirm_slip`, payload, {
      headers : {
         Authorization : `Bearer ${token}`
      }
   })
}

export const saveBatch = (payload, token) => {
   return axios.post(`${API_URL}/batch/save`, payload, {
      headers : {
         Authorization : `Bearer ${token}`
      }
   })
}

export const getHistoryOrders = (payload, token ) => {
   return axios.post(`${API_URL}/orders/get_history`,payload, {
      headers : {
         Authorization : `Bearer ${token}`
      }
   })
}
export const getHistorySalesOrders = (payload, token ) => {
   return axios.post(`${API_URL}/orders/sales/get_history`,payload, {
      headers : {
         Authorization : `Bearer ${token}`
      }
   })
}

export const geySalesOrdersFarmer = (payload, token ) => {
   return axios.post(`${API_URL}/orders/get_farmers`,payload, {
      headers : {
         Authorization : `Bearer ${token}`
      }
   })
}
export const getBatchSalesOrders = (payload, token ) => {
   return axios.post(`${API_URL}/batch/sales/getBatchOrders`,payload, {
      headers : {
         Authorization : `Bearer ${token}`
      }
   })
}
export const getBatchOrders = (payload, token ) => {
   return axios.post(`${API_URL}/batch/getBatchOrders`,payload, {
      headers : {
         Authorization : `Bearer ${token}`
      }
   })
}
export const updateBatch = (payload, token ) => {
   return axios.put(`${API_URL}/batch/update`,payload, {
      headers : {
         Authorization : `Bearer ${token}`
      }
   })
}


export const getBrandByProduct = (payload,token) => {
   return axios.post(`${API_URL}/brand/getByProduct`,payload, {
      headers: {
         Authorization: `Bearer ${token}`
      }
   })
}