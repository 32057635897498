import Table from "../table"
import SearchIcon from '@mui/icons-material/Search';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Tooltip, IconButton, Typography } from '@mui/material'

const WarehouseTable = ({ rows, onProcess,role_id }) => {

const columns = [
    { field: 'batch_no', headerName: 'หมายเลขชุด', flex: 0.2, align: 'center' },
    { field: 'product', headerName: 'ชื่อสินค้า', flex: 0.5, align: 'center' },
    { field: 'create_date', headerName: 'วันที่ผลิต', flex: 0.2, align: 'center' },
    { field: 'quantity', headerName: 'จำนวน', flex: 0.2, align: 'center' },
    ...(role_id != 3 ? [{
        field: 'change',
        headerName: 'แปรรูป',
        flex: 0.2,
        align: 'center',
        renderCell: (params) => (
            <Tooltip title={<Typography>แปรรูป</Typography>}>
                <IconButton color="primary" onClick={() => onProcess(params.row)}>
                    <AutorenewIcon sx={{ color: '#15322D', fontSize: 35 }} />
                </IconButton>
            </Tooltip>
        )
    }] : []),
    {
        field: 'accept',
        headerName: 'รายละเอียด',
        flex: 0.2,
        align: 'center',
        renderCell: (params) => (
            <Tooltip title={<Typography>ข้อมูล</Typography>}>
                <IconButton color="primary" onClick={() => console.log('detail')}>
                    <SearchIcon sx={{ color: '#15322D', fontSize: 35 }} />
                </IconButton>
            </Tooltip>
        )
    },
];


    const columns_2 = [
        {
            field: 'index',
            headerName: 'No',
            width: 0.1,
            headerAlign: 'center',
            align: 'center',
            // hide: true
        },
        // {
        //     field: 'No',
        //     headerName: 'No.',
        //     width: 100,
        //     headerAlign: 'center',
        //     align: 'center',
        //     renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,

        // },
        { field: 'product', headerName: 'ชื่อสินค้า', flex: 0.5, align: 'center' },
        { field: 'create_date', headerName: 'วันที่ผลิต', flex: 0.2, align: 'center' },
        { field: 'quantity', headerName: 'จำนวน', flex: 0.2, align: 'center' },
    ]
    return (
        <div style={{ height: 600, width: "100%" }}>
            <Table
                rows={rows}
                columns={role_id==='4'?columns_2:columns}
            />
        </div>
    )
}
export default WarehouseTable