import Table from "../table"
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Tooltip, Typography } from "@mui/material"
import Modal from '@mui/material/Modal';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button } from "@mui/material"
import { orderUpdateSlip, } from "../../services/orders"
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
const user_id = localStorage.getItem('user_id')
const role_id = localStorage.getItem('role_id')
const config = require('../../config')

const API_URL = config.API_URL

const token = localStorage.getItem('accessToken')

const HistoryTable = ({ rows }) => {
    const [open_slip, setOpenslip] = useState(false)
    const [img, setIMG] = useState(null)
    const [img_upload, setIMGUpload] = useState('')
    const [order_id, setOrderId] = useState('')
    const [confirm_payment, setConfirmPayment] = useState('')

    const [selectedFile, setSelectedFile] = useState(null)


    const columns = [
        { field: 'orders_no', headerName: 'เลขคำสั่งซื้อ', width: 200, align: 'center' },
        { field: 'orderer', headerName: 'ผู้สั่ง', width: 300, align: 'center' },
        { field: 'order_date', headerName: 'วันที่สั่ง', width: 200, align: 'center' },
        { field: 'status', headerName: 'สถานะ', width: 100, align: 'center' },
        { field: 'product', headerName: 'สินค้า', width: 200, align: 'center' },
        { field: 'quantity', headerName: 'จำนวน', width: 100, align: 'center' },
        { field: 'delivery_date', headerName: 'วันที่ส่งมอบ', width: 200, align: 'center' },
        {
            field: "detail",
            headerName: "รายละเอียด",
            width: 150,
            align: 'center',
            renderCell: (params) => (
                <Tooltip title={<Typography>รายละเอียด</Typography>} >
                    <IconButton color="primary" onClick={() => { window.location.href = 'history/' + params.row.id }}>
                        <SearchIcon sx={{ color: '#15322D', fontSize: 40 }} />
                    </IconButton>
                </Tooltip>
            ),
        }
       
    ]
    if (role_id !== '3') {
        columns.push({
            field: "slip",
            headerName: "อัพโหลดslip",
            width: 150,
            align: 'center',
            renderCell: (params) => (
                <Tooltip title={<Typography>อัพโหลดslip</Typography>} >
                    <IconButton color="primary" onClick={() => showSlip(params.row)}>
                        <FileUploadIcon sx={{ color: '#15322D', fontSize: 40 }} />
                    </IconButton>
                </Tooltip>
            ),
        });
    }
    if (role_id == '2') {
        columns.push({
            field: "pay_farmer",
            headerName: "การจ่ายเงินเกษตรกร",
            width: 300,
            align: 'center',
            renderCell: (params) => (
                <Tooltip title={<Typography>การจ่ายเงินเกษตรกร</Typography>} >
                    <IconButton color="primary" onClick={()=>window.location.href = 'payment/' + params.row.id }>
                        <LocalAtmIcon sx={{ color: '#15322D', fontSize: 40 }} />
                    </IconButton>
                </Tooltip>
            ),
        });
    }
    const showSlip = (row) => {
        setIMG(row.img_slip)
        setOrderId(row.id)
        setConfirmPayment(row.confirm_payment)
        setOpenslip(true)
    }
    const update = (url) => {

        orderUpdateSlip({
            user_id: user_id,
            img_slip: url,
            order_id: order_id
        }, token)
            .then((res) => {
                window.location.reload();
                setOpenslip(true)
            }).catch(error => {
                console.log(error)
            })
    }

    const style_assign = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
    };
    return (
        <div style={{ height: 600, width: "100%" }}>
            <Modal
                open={open_slip}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 2 }}
            >
                <Box sx={style_assign}>
                    <IconButton
                        style={{ position: "absolute", top: "0", right: "0" }}
                        onClick={() => setOpenslip(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <h3>อัพโหลดหลักฐานการโอนเงิน</h3>
                    {img ? (
                        <img
                            src={img}
                            alt="หลักฐานการโอนเงิน"
                            style={{ width: "100%", height: "auto", marginTop: "20px" }}
                        />


                    ) : (
                        <> <input
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                                const file = event.target.files?.[0];
                                if (file) {
                                    setSelectedFile(file); // เก็บไฟล์ใน state
                                }
                            }}
                            style={{ marginTop: "20px" }} /><Button
                                variant="contained"
                                style={{
                                    marginTop: "20px",
                                    float: "right", // ปุ่มชิดขวา
                                }}
                                onClick={async () => {
                                    if (!selectedFile) {
                                        alert("กรุณาเลือกไฟล์ก่อน");
                                        return;
                                    }

                                    const formData = new FormData();
                                    formData.append("files", selectedFile);
                                    formData.append("type", 'slip');
                                    formData.append("order_id", order_id);
                                    
                                    try {
                                        const response = await fetch(API_URL+"/upload", {
                                            method: "POST",
                                            headers: {'Authorization': `Bearer ${token}`},
                                            body: formData,
                                        });
                                        console.log(response)
                                        if (response.status) {
                                            console.log(response)
                                            alert("อัพโหลดไฟล์สำเร็จ");
                                            const result = await response.json();
                                            setIMGUpload(result.url);
                                            update(result.url)
                                        } else {
                                            alert("การอัพโหลดล้มเหลว");
                                        }
                                    } catch (error) {
                                        console.error("เกิดข้อผิดพลาด:", error);
                                        alert("เกิดข้อผิดพลาดในการอัพโหลดไฟล์");
                                    }
                                }}
                            >
                                อัพโหลดไฟล์
                            </Button></>
                    )}
                      {confirm_payment == 1 ? (
                        <p style={{ marginTop: "20px", color: "green", fontWeight: "bold" }}>
                            ยืนยันการรับเงินแล้ว
                        </p>
                    ) : (
                        <p style={{ marginTop: "20px", color: "red", fontWeight: "bold" }}>
                            ยังไม่ได้ยืนยันการรับเงิน
                    </p>
                    )}
                </Box>
            </Modal>


            <Table
                rows={rows}
                columns={columns}
            />
        </div>
    )
}

export default HistoryTable