import Table from "../table"
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import { IconButton, Tooltip, Typography } from "@mui/material"

const ShippingTable = ({ rows, handleAccept }) => {
    const columns = [
        // { field: 'id', headerName: 'ID', width: 0, align: 'center' },
        { field: 'orders_no', headerName: 'เลขคำสั่งซื้อ', align: 'center' ,width: 150, },
        { field: 'orderer', headerName: 'ผู้สั่ง', align: 'center' ,width: 300, },
        { field: 'order_date', headerName: 'วันที่สั่ง',  align: 'center',width: 150, },
        { field: 'status', headerName: 'สถานะ',  align: 'center',width: 150, },
        { field: 'product', headerName: 'สินค้า',  align: 'center',width: 300, },
        { field: 'quantity', headerName: 'จำนวน',  align: 'center',width: 150, },
        { field: 'delivery_date', headerName: 'วันที่ส่งมอบ',  align: 'center',width: 200, },
        {
            field: "accept",
            headerName: "ตอบรับสินค้า",
            align: 'center',
            width: 270,
            renderCell: (params) => (
                <Tooltip title={<Typography>ตอบรับคำสั่งซื้อ</Typography>} >
                    <IconButton color="primary" onClick={() => handleAccept(params.row.id)}>
                        <ThumbsUpDownIcon sx={{ color: '#15322D', fontSize: 35 }} />
                    </IconButton>
                </Tooltip>
            )
        },
       
    ]
    return (
        <div style={{ height: 600, width: "100%" }}>
            <Table
                columns={columns}
                rows={rows}
            />
        </div>
    )
}
export default ShippingTable