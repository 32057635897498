import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';

const TransactionCard = ({ rows }) => {
  return (
    <Box sx={{ padding: 1 }}>
      <Grid container spacing={1}>
        {rows.map((rows) => (

          <Grid item xs={12} key={rows.id}>
            <Card sx={{ minWidth: 275 }} onClick={() => { window.location.href = 'farmer/' + rows.id }}>
              <CardContent>
                <Typography style={{ fontSize: 16 }} component="div">
                  เลขคำสั่งซื้อ: {rows.orders_no}
                </Typography>
                <Typography style={{ fontSize: 14 }} color="text.secondary">
                  วันที่สั่งซื้อ: {rows.order_date}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'black', // สีของคำว่า "สถานะ"
                  }}
                >
                  สถานะ:
                  <span
                    style={{
                      marginLeft: '4px',
                      color:
                        rows.status === 5
                          ? 'red'
                          : rows.status === 4
                            ? 'green'
                            : rows.status === 3
                              ? 'blue'
                              : rows.status === 2
                                ? 'orange'
                                : rows.status === 1
                                  ? 'gray' : 'gray'
                    }}
                  >
                    {rows.status_desc}  {/* สีของข้อความที่แสดงสถานะ */}
                  </span>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TransactionCard;
